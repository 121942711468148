import { BlogCardFooter } from "./BlogCardFooter"
import { Text } from "./theme"

export const BlogCard = ({ blog, index }) => {
    return index !== 0 && (
        <div key={index} className='border-[1px] border-[#2E2E32] bg-[#1C1C1F] rounded-xl lg:rounded-2xl p-2 lg:p-5 w-full flex gap-2 lg:gap-5 cursor-pointer slide_lite'>
            <div className='w-32 lg:w-1/3'>
                <img src={blog.image} alt="Main Article" className='rounded-md lg:rounded-xl' />
            </div>
            <div className='w-3/4 flex flex-col justify-between'>
                <Text className="font-semibold text-[12px] line-clamp-1">{blog.title}</Text>
                <p className='opacity-50 text-slate-50 text-left text-[10px] line-clamp-2'>{blog.description}</p>
                <BlogCardFooter date={blog.date} />
            </div>
        </div>
    )
}