import { H2, Tag } from './theme';
import { ProjectCardFooter } from './ProjectCardFooter';

export const ProjectCard = ({ project }) => {
    return (
        <div className="bg-[#1C1C1F] border-[#2E2E32] border-[1px] my-3 lg:my-8 p-3 lg:p-10 flex flex-col lg:flex-row gap-5 lg:justify-between rounded-xl lg:rounded-2xl">
            <div className='relative w-full lg:w-2/3 flex flex-col gap-5'>
                <H2>{project.name}</H2>
                <p className='font_space font-normal text-sm lg:text-xl text-left text-slate-50 opacity-50 w-fit'>
                    {project.description}
                </p>

                <div className='flex gap-2 flex-wrap'>
                    {
                        project.tech.map((tech, index) => {
                            return (
                                <Tag key={index}>{tech}</Tag>
                            )
                        })
                    }
                </div>
                <ProjectCardFooter project={project} />
            </div>
            <div className='w-full lg:w-1/4'>
                <img src={project.banner} alt="Project Banner" className="rounded-md lg:rounded-xl" />
            </div>
        </div>
    )
}