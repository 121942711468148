import { AiOutlineGlobal } from 'react-icons/ai';
import { FiGithub } from 'react-icons/fi';
import { SiLoom } from 'react-icons/si';
import { Button } from './theme';

export const ProjectCardFooter = ({ project, className }) => {
    return (
        <div className={'lg:absolute bottom-0 left-0 flex justify-between lg:justify-start lg:gap-5'+" "+className}>
            <Button className="flex justify-center items-center gap-2 lg:gap-4 min-w-fit text-sm">
                <AiOutlineGlobal />
                DEMO
            </Button>
            <Button className="flex justify-center items-center gap-2 lg:gap-4 min-w-fit text-sm">
                <FiGithub />
                GITHUB
            </Button>
            <Button className="flex justify-center items-center gap-2 lg:gap-4 min-w-fit text-sm">
                <SiLoom />
                LOOM
            </Button>
        </div>
    )
}