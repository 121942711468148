import React from 'react';
import { H1, SocialIcon } from '../theme';
import { PiGithubLogoFill } from 'react-icons/pi';
import { AiFillLinkedin, AiFillMail, AiOutlineTwitter } from 'react-icons/ai';
import { BsDiscord } from 'react-icons/bs';

export const SocialLinks = () => {
    return (
        <div className='p-7 lg:px-[100px] lg:py-[100px]'>
            <H1>Let's connect, collaborate, and work together</H1>
            <div className='flex gap-5 lg:gap-10 mt-7 lg:mt-[60px]'>
                <SocialIcon>
                    <PiGithubLogoFill />
                </SocialIcon>
                <SocialIcon>
                    <AiFillLinkedin />
                </SocialIcon>
                <SocialIcon>
                    <AiFillMail />
                </SocialIcon>
                <SocialIcon>
                    <AiOutlineTwitter />
                </SocialIcon>
                <SocialIcon>
                    <BsDiscord />
                </SocialIcon>
            </div>
        </div>
    )
}