import React from 'react';

const Footer = () => {
    return (
        <div className="p-5 lg:p-[50px] border-t-[1px] border-[#1C1C1C]">
            <p className='text-[#838383] text-sm lg:text-xl font_clash font-normal'>2023 • AKASH VAGHELA • ALL RIGHTS RESERVED</p>
        </div>
    )
}

export { Footer }