import socialSenseBanner from "../assets/projects/social-sense.png";

export const data = {
    "blogs": [
        {
            "title": "001 How to use React Context API",
            "description": "React Context API is a way to essentially create global variables that can be passed around in a React app. This is the alternative to \"prop drilling\", or passing props from grandparent to parent to child, and so on.",
            "date": "27 April, 2023",
            "link": "https://www.example.com",
            "image": socialSenseBanner
        },
        {
            "title": "002 How to use React Context API",
            "description": "React Context API is a way to essentially create global variables that can be passed around in a React app. This is the alternative to \"prop drilling\", or passing props from grandparent to parent to child, and so on.",
            "date": "27 April, 2023",
            "link": "https://www.example.com",
            "image": socialSenseBanner
        },
        {
            "title": "003 How to use React Context API",
            "description": "React Context API is a way to essentially create global variables that can be passed around in a React app. This is the alternative to \"prop drilling\", or passing props from grandparent to parent to child, and so on.",
            "date": "27 April, 2023",
            "link": "https://www.example.com",
            "image": socialSenseBanner
        },
        {
            "title": "004 How to use React Context API",
            "description": "React Context API is a way to essentially create global variables that can be passed around in a React app. This is the alternative to \"prop drilling\", or passing props from grandparent to parent to child, and so on.",
            "date": "27 April, 2023",
            "link": "https://www.example.com",
            "image": socialSenseBanner
        }
    ],
    "projects": [
        {
            "name": "Social Sense",
            "description": "Social Sense is a social media app that allows users to share their thoughts, photos, and videos with other users. Users can also like and comment on other users' posts.",
            "tech": ["React", "Redux", "Node.js", "Express", "MongoDB", "Mongoose", "Material UI", "Heroku", "Netlify"],
            "banner": socialSenseBanner,
            "github": "https://github.com/akashvaghela09",
            "live": "https://social-sense.netlify.app/",
            "loom": "https://www.loom.com/share/8b5b8b5b5b5b5b5b5b5b5b5b5b5b5b5b"
        },
        {
            "name": "Pipe Plus",
            "description": "Pipe Plus is a social media app that allows users to share their thoughts, photos, and videos with other users. Users can also like and comment on other users' posts.",
            "tech": ["React", "Redux", "Node.js", "Express", "MongoDB", "Mongoose", "Material UI", "Heroku", "Netlify"],
            "banner": socialSenseBanner,
            "github": "https://github.com/akashvaghela09",
            "live": "https://social-sense.netlify.app/",
            "loom": "https://www.loom.com/share/8b5b8b5b5b5b5b5b5b5b5b5b5b5b5b5b"
        },
        {
            "name": "Score Card",
            "description": "",
            "tech": ["React", "Redux", "Node.js", "Express", "MongoDB", "Mongoose", "Material UI", "Heroku", "Netlify"],
            "banner": socialSenseBanner,
            "github": "https://github.com/akashvaghela09",
            "live": "https://social-sense.netlify.app/",
            "loom": "https://www.loom.com/share/8b5b8b5b5b5b5b5b5b5b5b5b5b5b5b5b"
        }
    ],
}