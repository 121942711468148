import React from 'react';
import { H1, Text, Button } from '../theme';

const Hero = () => {
    return (
        <div className="wrapper  px-7 lg:py-[100px] lg:px-[100px] flex flex-col lg:justify-between lg:flex-row">
            <div className='order-last w-fit lg:w-1/2 lg:p-0 py-7'>
                <H1>Hi <br /> I'm Akash Vaghela <br /> Full Stack Developer </H1>
                <Text className="my-4">
                    I'm a Blockchain enthusiast and passionate Solidity smart contract developer with a problem-solving mindset. Strong in JavaScript, ReactJs and NodeJs skills. Has a 2+ years of experience in developing full-stack web apps. proficient in working with various tool sets such as HardHat, Truffle, Ganache.
                </Text>
                <Text className="my-4">
                    Strong in JavaScript, ReactJs and NodeJs skills. Has a 2+ years of experience in developing full-stack web apps. proficient in working with various tool sets such as HardHat, Truffle, Ganache.
                </Text>
                <div className='py-5 lg:py-[48px] flex justify-start gap-8 lg:gap-20 items-center'>
                    <Button type="primary">
                        RESUME
                    </Button>
                    <Button type="secondary">
                        LET'S CONNECT
                    </Button>
                </div>
            </div>
            <div className='w-fit lg:w-1/2 flex justify-start lg:justify-end items-center lg:order-last'>
                <img src="./icon512.png" alt="Akash Vaghela" className='w-3/5 lg:w-1/2'/>
            </div>
        </div>
    )
}

export { Hero }