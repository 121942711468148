import React from 'react';

export const Button = ({ children, type, className }) => {
    return (
        type === "primary" || type === undefined ?
            <button className={`px-2 py-1 lg:px-[16px] lg:py-[8px] border-[1px] border-[#00B8FE] hover:bg-[#00B8FE] hover:text-[#0F0F0F] text-slate-50 font_space font-bold min-w-[100px] lg:min-w-[200px] select-none transition-all duration-300 slide ${className}`}>
                {children}
            </button>
            :
            <div className='relative'>
                <button className='bg-[#00B8FE] border-[#00B8FE] px-2 py-1 lg:px-[16px] lg:py-[8px] font_space font-bold border-2 absolute top-0 left-0 z-20 transform slide whitespace-nowrap min-w-[100px] lg:min-w-[200px] transition-all duration-300 select-none'>{children}</button>
                <button className='bg-transparent px-2 py-1 lg:px-[16px] lg:py-[8px] font_space font-bold text-transparent border-[1px] border-slate-50 absolute top-[7px] left-[7px] z-10 whitespace-nowrap min-w-[100px] lg:min-w-[200px] select-none'>{children}</button>
                <button className='bg-transparent px-2 py-1 lg:px-[16px] lg:py-[8px] font_space font-bold whitespace-nowrap min-w-[100px] lg:min-w-[200px] select-none'>{children}</button>
            </div>
    )
}