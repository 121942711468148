import React, { useState } from 'react';
import { Button, H1 } from '../theme';
import { data } from "../../data/data.js";
import { ProjectCard } from '../ProjectCard';

const Project = () => {

    const [projectList, setProjectList] = useState(data.projects);

    return (
        <div className="p-7 lg:p-[100px] flex flex-col">

            <H1>Projects</H1>

            {
                projectList.map((project, index) => {
                    return (
                        <ProjectCard project={project} key={index} />
                    )
                })
            }

            <div className='flex justify-center mt-4 lg:mt-12'>
                <Button type="secondary">VIEW MORE</Button>
            </div>

        </div>
    )
}

export { Project }