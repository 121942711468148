import './App.css';
import { Header, Hero, Project, Blog, Footer, SocialLinks } from './components/sections';

export default function App() {
  return (
    <div className="App relative">
      <Header />
      <Hero />
      <Project />
      <Blog />
      <SocialLinks />
      <Footer />
    </div>
  )
}