import { FaCalendarAlt } from 'react-icons/fa';
import { BsArrowRight } from 'react-icons/bs';

export const BlogCardFooter = ({ date }) => {
    return (
        <div className='flex justify-between'>
            <div className='flex items-baseline'>
                <FaCalendarAlt className='text-slate-50 opacity-30 text-[8px] lg:text-base' />
                <p className='text-slate-50 opacity-30 text-[8px] lg:text-lg font-normal font_space'>{date}</p>
            </div>
            <div className='hidden lg:flex justify-center items-center lg:gap-3 cursor-pointer'>
                <p className='text-[#00B8FE] text-[8px] lg:text-lg font-bold font_space'>Read More</p>
                <BsArrowRight className='text-[#00B8FE] text-[8px] lg:text-lg font-bold' />
            </div>
        </div>
    )
}