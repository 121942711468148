import React, { useState } from 'react';
import { Button, H1 } from '../theme';
import { BlogCard } from '../BlogCard';
import { BlogCardLarge } from '../BlogCardLarge';
import { data } from "../../data/data.js";

const Blog = () => {

    const [blogList, setBlogList] = useState(data.blogs);

    return (
        <div className="p-7 lg:p-[100px] flex flex-col">

            <H1>Blogs</H1>

            <div className='flex gap-14 my-4 lg:my-8'>

                <BlogCardLarge className="w-1/2" blog={blogList[0]} />

                <div className='grow w-full lg:w-1/2 flex flex-col gap-4 lg:justify-between'>
                    {
                        blogList.map((blog, index) => {
                            return <BlogCard blog={blog} index={index} key={index}/>
                        })
                    }
                </div>
            </div>

            <div className='flex justify-center lg:mt-12'>
                <Button type="secondary">READ MORE</Button>
            </div>

        </div>
    )
}

export { Blog }