import React from 'react';

const SocialIcon = ({children}) => {
    return (
        <div className="flex justify-center items-center rounded-[50%] w-10 h-10 lg:w-12 lg:h-12 text-xl lg:text-2xl border-[1px] border-[#303030] text-[#575757] cursor-pointer hover:bg-[#00B8FE] hover:text-[#0F0F0F] slide_lite">
            {children}
        </div>
    )
}

export { SocialIcon }