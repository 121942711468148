import React from 'react';
import logo from "../../assets/logo.png";
import { Logo } from '../Logo';

const Header = () => {

    const HButton = ({text}) => {
        return (
            <button className='text-slate-50 font-[400] font_space hover:text-[#00B8FE] transition-all duration-300'>
                {text}
            </button>
        )
    }

    return (
        <div className="w-screen h-[80px] p-6 lg:px-[100px] lg:py-[25px] flex justify-between items-center sticky bg-[#0F0F0F] top-0 left-0 z-50">
            <Logo />
            <div className='lg:flex gap-[24px] hidden'>
                <HButton text="ABOUT"/>
                <HButton text="WORK"/>
                <HButton text="CONTACT"/>
                <HButton text="RESUME"/>
            </div>
        </div>
    )
}

export { Header }