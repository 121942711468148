import { BlogCardFooter } from "./BlogCardFooter"
import { Text } from "./theme"

export const BlogCardLarge = ({ blog, className }) => {
    return (
        <div className={className+" "+"border-[1px] border-[#2E2E32] bg-[#1C1C1F] rounded-2xl hidden lg:flex flex-col cursor-pointer"}>
            <div className='w-full'>
                <img src={blog.image} className='rounded-t-2xl' alt="Project banner"/>
            </div>
            <div className='flex flex-col items-start space-y-3 p-5'>
                <Text className="font-semibold">{blog.title}</Text>
                <p className='opacity-50 text-slate-50 text-left'>{blog.description}</p>
                <BlogCardFooter date={blog.date} />
            </div>
        </div>
    )
}